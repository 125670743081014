<template>
  <QuizEditView disabled />
</template>

<script>
import QuizEditView from '@/views/quiz/QuizEditView'

export default {
  name: 'QuizView',
  components: {
    QuizEditView
  }
}
</script>
